import {
	json,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { useSearchParams } from '@remix-run/react'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { Icon } from '#app/components/ui/icon.tsx'
import { requireAnonymous } from '#app/utils/auth.server.ts'
import {
	ProviderConnectionForm,
	providerNames,
} from '#app/utils/connections.tsx'

export async function loader({ request }: LoaderFunctionArgs) {
	await requireAnonymous(request)
	return json({})
}

export default function LoginPage() {
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get('redirectTo')

	return (
		<div className="flex h-full flex-col md:flex-row">
			<div className="flex h-full flex-1 items-center justify-center bg-gradient-to-br from-[#4e008e] to-black dark:bg-transparent">
				<Icon className="text-white" size="hero" name="jazz-logo" />
			</div>
			<div className="flex h-full flex-1 items-center justify-center p-4">
				<div className="max-w-md space-y-4">
					<div>
						<h3 className="text-h3">Welcome!</h3>
						<h5 className="text-h5 text-muted-foreground">
							Log in with your Utah Jazz email.
						</h5>
					</div>
					{providerNames.map(providerName => (
						<ProviderConnectionForm
							key={providerName}
							type="Login"
							providerName={providerName}
							redirectTo={redirectTo}
						/>
					))}
				</div>
			</div>
		</div>
	)
}

export const meta: MetaFunction = () => {
	return [{ title: 'Login to Jazz Hub' }]
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
